@import "../../../../../basics-scss/mixins";

.Filter {
  flex: 1;
  margin: 0;
  background: $colorNeutral000;
  border-radius: 4px;
  border: solid 1px #cacfdd;
  position: relative;
}

.FilterControls {
  display: flex;
  padding: 24px 36px 24px 36px;
  border-bottom: 1px solid $colorNeutral080;

  .ControlBlock {
    .FilterLabel {
      line-height: 32px;
      margin: 0 4px 0 0;
      width: 135px;
      display: inline-block;
      vertical-align: top;
    }
    .Button {
      margin-right: 8px;
    }
    &:first-child {
      margin-right: auto;
    }
  }
}

.FilterControlsSep {
  display: inline-block;
  vertical-align: top;
  width: 2px;
  height: 24px;
  background-color: #D0D0D0;
  border-right: 1px solid #AAA;
  margin: 0 5px;
}

.FilterConditions  {
  padding: 0;
}

.FilterSorts {
  padding: 0;
}

.FilterContent {
  padding: 0 36px 12px 36px;
  min-height: 40px;
}

.FilterSortLabel {
  color: #278efc;
}

.FilterLabel {
  font-size: 14px;
  line-height: 1;
  margin: 32px 0 13px;
  color: #334d6e;
}

.SubFilterLabel {
  font-size: 14px;
  margin-bottom: 10px;
  color: #707070;
  padding: 10px 0;
  border-top: 1px solid $colorNeutral080;
}

.SaveToolbar {
  padding: 10px 10px;
  display: flex;
  align-items: center;

  input {
    width: 250px;
    margin: 0 5px;
  }
}

.BorderBottom {
  border-bottom: 1px solid $colorNeutral080;
}

.Row {
  display: flex;
  margin: 0 -2px 10px;
  padding: 10px;
}

.Fields {
  flex: 3;
  margin-right: 5%;
  display: flex;
  position: relative;
  align-items: center;
}

.Buttons {
  flex: .8;

  .Button {
    margin: 0 2px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.BreadcrumbsList {
  padding-top: 8px;
  li {
    font-size: $fontSizeCaption;
    margin: 0 4px 0;
    display: inline-block;
    vertical-align: top;
    max-width: 200px;
    height: 18px;
    line-height: 18px;
    color: #334d6e;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      margin: 0;
    }

    &:nth-of-type(n + 2) {
      position: relative;
    }
  }

  @include forMedia(sm){
    overflow-x: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.ChevronRight {
  position: relative;
  top: 4px;
  @include svgIcon(12px,12px);
}

.ClearFix {
  &:after {
    display: block;
    content: '';
    clear: both;
  }
  @include forMedia(sm){
    display: flex;
  }
}

.ShowButton {
  float: left;
  margin-right: 8px;
  flex: 0 0 32px;
}

.ShowButtonIcon {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  @include svgIcon(100%,auto);
}

.MobileFilter {
  padding: 16px;
  .FilterLabel {
    text-transform: uppercase;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    margin: 0 0 16px;
    color: $colorNeutral600;
    font-weight: 600;
  }
  .FilterContent {
    padding: 0;
  }
  .FilterControls {
    padding: 0 16px;
    flex-direction: column;
    margin: 0 -16px 16px;
    .FilterLabel {
      margin: 0 0 16px;
      display: block;
      line-height: $lineHeightTitle;
      width: auto;
    }
    .ControlBlock {
      margin: 0 0 16px;
      &:last-child {
        order: -1;
        .Button {
          width: 100%;
        }
      }
    }
  }
}