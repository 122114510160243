@import "../../basics-scss/mixins";

.Heading {
    font-family: $fontFamilyHeadline;
    color: $colorNeutral900;
    &.sizeH1 {
        font-size: $fontSizeH1;
        line-height: $lineHeightH1;
        @include forMedia(sm) {
            font-size: $fontSizeH3;
            line-height: $lineHeightH3;
        }
    }
    &.sizeH2 {
        font-size: $fontSizeH2;
        line-height: $lineHeightH2;
        @include forMedia(sm) {
            font-size: $fontSizeH3;
            line-height: $lineHeightH3;
        }
    }
    &.sizeH3 {
        font-size: $fontSizeH3;
        line-height: $lineHeightH3;
    }
    &.sizeH4 {
        font-family: $fontFamilyMain;
        font-size: $fontSizeH4;
        line-height: $lineHeightH4;
    }
    &.isBold {
        font-weight: 600;
    }
    &.hasMargin {
        margin-bottom: 24px;
    }
}
