@import "../../basics-scss/mixins";

.preloader {
  position: relative;
  background: $colorNeutral000 url("/assets/img/page-loader.gif") no-repeat center;
  background-size: 64px 64px;
  height: 200px;
}

.popUp {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $colorOverlay;

  @include forMedia(sm){
    background-color: $colorNeutral000;
    display: block;
  }
}

.popUpOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.popUpContainer {
  position: relative;
  width: 512px;
  border-radius: 4px;
  box-shadow: $boxShadowModal;
  background-color: $colorNeutral000;

  @include forMedia(sm){
    width: 100%;
    box-shadow: none;
  }
}

.header {
  padding: 24px 24px;
  position: relative;

  @include forMedia(sm){
    padding: 16px 16px;
    border-bottom: 1px solid $colorNeutral080;
    display: flex;
    align-items: center;
  }
}

.headerText {
  font-size: $fontSizeH2;
  line-height: $lineHeightH2;
  color: $colorNeutral900;
  font-family: $fontFamilyHeadline;
  font-weight: 600;
  padding-right: 32px;

  @include forMedia(sm){
    text-transform: uppercase;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    color: $colorNeutral600;
    font-family: $fontFamilyMain;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 0;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;

  @include forMedia(sm){
    position: static;
    margin-left: auto;
    flex: 0 0 32px;
  }
}

.footer {
  padding: 24px 24px;
  text-align: right;
  & > * {
    margin-left: 8px;
  }
  @include forMedia(sm){
    padding: 0 16px 16px;
    & > * {
      width: 100%;
      margin: 0 0 16px;
    }
  }
}

.content {
  padding: 0 24px;
  @include forMedia(sm){
    padding: 20px 16px;
  }
}

.rolesItem {
  @include clearFix;
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
}

.rolesItemLabel {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-left: 24px;
}

.rolesItemCheckbox {
  float: left;
  line-height: 0;
  position: relative;
  top: 3px;
}