@import "../../../basics-scss/mixins";

.breadcrumb {
  font-size: $fontSizeCaption;
  line-height: $lineHeightCaption;
  text-transform: uppercase;
  color: $colorNeutral600;
  display: flex;
  align-items: center;
  .item, .currentItem {
    position: relative;
    margin-left: 32px;
    color: $colorNeutral600;
    white-space: nowrap;
    &:first-child {
      margin-left: 0;
    }
  }
  .item {
    cursor: pointer;
  }
  .arrow {
    cursor: default;
    position: absolute;
    margin: -8px 0 0 8px;
    top: 50%;
    @include svgIcon(16px,16px,$colorNeutral600);
  }
}
