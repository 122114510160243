@import "../../../../basics-scss/mixins";

.Menu {
    @include dropDown;
    min-width: 160px;
    word-break: break-all;
    padding: 8px 0;

    &.Active {
        display: block;
    }

    .Element {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 6px 0;
        line-height: $lineHeightBody;
        cursor: pointer;

        &.Active {
            background-color: $colorBlue010;
        }

        .ElementTitle {
            font-size: $fontSizeBody;
            color: $colorNeutral800;
            width: 100%;
            padding: 0 16px;
        }

        &:hover {
            text-decoration: none;
            background-color: $colorNeutral070;
        }

        .Arrow {
            padding: 2px 8px 0 0;

            @include svgIcon(16px,16px);
        }
    }
}
