@import "../../basics-scss/mixins";

.Modal {
  .activityFeed {
    width: 100%;
  }
}

.Heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 18px;

  div {
    cursor: pointer;
  }

}

.ChevronActivityFeed {
  @include svgIcon(20px,20px);
  margin-left: 8px;
}

.ActivityFeed {
  background: $colorNeutral000;
  border: 1px solid $colorNeutral080;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;

  .GroupHeader {
    font-weight: normal;
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
    display: block;
    text-align: center;
    color: $colorNeutral600;
    margin: 16px 0;
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      background: $colorNeutral080;
    }
    span {
      display: inline-block;
      vertical-align: top;
      padding: 0 8px;
      background: $colorNeutral000;
      position: relative;
    }
  }
}

.Separator {
  width: 100%;
  height: 24px;
}

.NoActivities {
  color: $colorNeutral090;
  text-align: center;
  font-size: $fontSizeLabel;
  line-height: $lineHeightLabel;
  padding: 0 16px;
}

.IconNoActivities {
  @include svgIcon(20px, 20px, $colorNeutral090);
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
}

.ActivitiesContainer {
  @include customScrollbar;
  overflow-y: auto;
  max-height: 584px;
  &:after {
    content: '';
    display: block;
    height: 16px;
  }
}

.ActivitiesContainerWrap {
  overflow: hidden;
  position: relative;
  margin: 0 -16px -16px;
}
.ActivitiesContainerShadow {
  box-shadow: $boxShadowFixedAside;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  height: 10px;
}

.Skeleton {
  overflow: hidden;
  position: relative;
  &:after {
    @include animatedShadow(2s);
  }
}

.SkeletonBlank {
  border-radius: 4px;
  background: $colorNeutral077;
}

.SkeletonHeading {
  @extend .SkeletonBlank;
  margin-bottom: 18px;
  max-width: 168px;
  height: 24px;
}

.SkeletonTabs {
  display: flex;
  margin-bottom: 24px;
}

.SkeletonTabsArea {
  @extend .SkeletonBlank;
  flex: 1 1 auto;
  height: 79px;
}

.SkeletonTabsButton {
  @extend .SkeletonBlank;
  height: 32px;
  flex: 0 0 32px;
  margin-left: 8px;
  align-self: flex-end;
}

.SkeletonTags {
  padding-bottom: 8px;
}

.SkeletonTagsItem {
  @extend .SkeletonBlank;
  display: inline-block;
  vertical-align: top;
  height: 24px;
  max-width: 100%;
  margin: 0 8px 8px 0;
  &:nth-child(1) { width: 40px; }
  &:nth-child(2) { width: 104px; }
  &:nth-child(3) { width: 88px; }
  &:nth-child(4) { width: 134px; }
}

.SkeletonAct {
  overflow: hidden;
  position: relative;
  max-height: 584px;
  margin-bottom: -16px;
}

.SkeletonActItem {
  overflow: hidden;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}

.SkeletonActHeader {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.SkeletonActUserPic {
  @extend .SkeletonBlank;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  margin-right: 8px;
}

.SkeletonActUserName {
  @extend .SkeletonBlank;
  height: 20px;
  flex: 0 1 200px;
  margin-right: 8px;
}

.SkeletonActType {
  @extend .SkeletonBlank;
  height: 20px;
  flex: 0 1 88px;
  margin-left: auto;
}

.SkeletonActCont {
  margin: 0 0 0 40px;
}

.SkeletonActInf {
  @extend .SkeletonBlank;
  height: 20px;
  margin-bottom: 4px;
  &:nth-child(1) {
    max-width: 112px;
  }
  &:nth-child(2) {
    max-width: 288px;
    margin-bottom: 8px;
  }
  &:nth-child(3) {
    max-width: 56px;
  }
  &:nth-child(4) {
    max-width: 80px;
    margin-bottom: 0;
  }
}
