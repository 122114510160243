@import "../../../basics-scss/mixins";

.attachmentsContainer {
    width: 100%;
    position: relative;

    &.dropHovered {
        .dragBox {
            visibility: visible;
        }
    }

    &.hasAttachments {

    }

    .dndIcon {
        @include svgIcon(32px,32px,$colorBlue500);
        margin-right: 8px;
    }
}

.dragBox {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $colorNeutral000;
    border: 2px dashed $colorBlue300;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.dragBoxIcon {
    @include svgIcon(20px,20px,$colorBlue500);
    margin-right: 8px;
}

.dragBoxText {
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    color: $colorBlue500;
}

.addFiles {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 16px;
    border: 2px dashed $colorNeutral080;
    border-radius: 4px;
    margin-bottom: 16px;
    background: $colorNeutral000;
}

.addFilesText {
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    font-weight: 600;
    color: $colorNeutral800;
    margin: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.addFilesIcon {
    @include svgIcon(20px,20px,$colorNeutral800);
}

.wrapper {
    margin: 0;
}

.count {
    border: 1px solid #CACFDD;
    height: 40px;
    padding: 0 16px;
    line-height: 40px;
    border-radius: 24px;
    color: #6E728F;
    margin: 0 8px 8px 0;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
}

.uploadedFileContainer {
    padding: 0 8px;
    border: 1px solid $colorNeutral080;
    text-align: left;
    height: 40px;
    font-size: $fontSizeBody;
    display: block;
    cursor: pointer;
    background: $colorNeutral010;
    border-radius: 4px;
    margin: 0 0 8px 0;

    &:hover {
        background: $colorNeutral060;
    }

    .fileContent {
        @include clearFix;
        padding-top: 7px;

        & > div:first-child {
            float: left;
            @include svgIcon(24px,24px);
        }

        .fileParams {
            position: relative;
            margin-left: 32px;
            display: flex;
        }

        .fileName {
            flex: auto;
            position: relative;
            width: 100%;
            line-height: 24px;
            height: 24px;

            span {
                position: absolute;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                line-height: 24px;
                height: 24px;
                left: 0;
                top: 0;
            }

            a {
                color: inherit;
            }
        }

        .fileIcon {
            @include svgIcon(16px,16px,$colorNeutral090);
            margin-left: 8px;
            cursor: pointer;
            flex: 0 0 16px;
            align-self: center;

            &:hover {
                @include svgIconColor($colorNeutral800);
            }
        }
    }
}

.addBtn {
    flex: 1 1;
}

.addBtnWrap {
    display: flex;
}

.uploadedItems {

}

.emptyState {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    color: $colorNeutral600;
    font-weight: 600;
    background: $colorNeutral040;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
}

.Skeleton {
    position: relative;
    overflow: hidden;

    &:after {
        @include animatedShadow();
    }
}

.SkeletonItem {
    border-radius: 4px;
    background: $colorNeutral077;
    height: 20px;
}

.SkeletonTop {
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 22px;
    margin-bottom: 16px;

    .SkeletonItem {
        &:nth-child(1) {
            flex: 0 0 20px;
        }
        &:nth-child(2) {
            flex: 0 1 184px;
            margin: 0 8px;
        }
        &:nth-child(3) {
            flex: 0 1 200px;
        }

    }
}

.SkeletonListItem {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    .SkeletonItem {
        &:nth-child(1) {
            flex: 0 0 24px;
            height: 24px;
            margin-right: 8px;
        }
        &:nth-child(2) {
            flex: 0 1 241px;
            margin-right: auto;
        }
        &:nth-child(3),
        &:nth-child(4) {
            flex: 0 0 20px;
            margin-left: 8px;
        }

    }
}
