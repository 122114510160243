@import "../../basics-scss/mixins";

.FixedHeader {
    position: fixed;
    top: $headerHeight;
    height: 100px;
    padding: 42px 0 0 34px;
    background: $colorNeutral000;
    z-index: $zIndexMiddle;

    .ViewTitle {
        margin: 0 5px;
        font-family: $fontFamilyHeadline;
        font-size: 22px;
        color: $colorNeutral800;
    }
}
.Content {
    min-height: calc(100vh - #{$headerHeight});
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include forMedia(sm){
        padding: 16px;
        text-align: center;
    }
}
.Screen {
    font-family: $fontFamilyHeadline;
    font-size: 135px;
    line-height: 165px;
    color: $colorNeutral090;
    margin: 0 auto 36px;

    @include forMedia(sm){
        margin-bottom: 18px;
        font-size: 88px;
        line-height: 96px;
    }
}
.Title {
    color: $colorNeutral800;
    font-family: $fontFamilyHeadline;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    @include forMedia(sm){
        font-size: $fontSizeH3;
        line-height: $lineHeightH3;
    }
}
.Text {
    color: $colorNeutral600;
    font-family: $fontFamilyMain;
    font-size: 16px;
    margin-top: 8px;
    line-height: 24px;

    @include forMedia(sm){
        margin-top: 12px;
        font-size: $fontSizeBody;
        line-height: $lineHeightBody;
    }
}
.LinkWrap {
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    margin: 32px 0 0 0;

    @include forMedia(sm){
        margin-top: 20px;
    }
}



