@import "../../../basics-scss/mixins";

.ModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: $colorNeutral800;
    font-size: $fontSizeH3;
    line-height: $lineHeightH3;

    @include forMedia(sm) {
        display: none;
    }
}

.ModalHeaderMobile {
    display: none;

    @include forMedia(sm) {
        display: block;
    }
}

.ModalFooterMobile {
    display: none;

    @include forMedia(sm) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.ModalHeaderMobileTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: $fontSizeH3;
    color: $colorNeutral800;

    @include forMedia(sm) {
        text-transform: uppercase;
        font-size: $fontSizeTitle;
        color: $colorNeutral600;
        height: 60px;
        padding: 16px 16px;
    }
}

.ModalHeaderH2 {
    margin-top: 30px;
    color: $colorNeutral900;
    font-weight: 600;
    font-size: $fontSizeH2;
    line-height: $lineHeightH2;

    @include forMedia(sm) {
        display: none;
    }
}

.ModalHeaderMobileH2 {
    display: none;

    @include forMedia(sm) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $colorNeutral900;
        font-size: $fontSizeH3;
        font-weight: 600;
        line-height: $lineHeightH3;
        margin: 16px 0;
    }
}

.ModalWindow {
    width: 800px;
    padding: 0;

    @include forMedia(sm) {
        width: 100%;
        height: 100%;
    }
}

.ModalWindowHeader {
    @include forMedia(sm) {
        padding: 0;
        border-bottom: 1px solid $colorNeutral080;
    }
}

.ModalHeaderAttach {
    margin-left: 4px;
}

.ModalHeaderBtn {
    margin-left: 4px;

    @include forMedia(sm) {
        width: 100%;
        margin-left: 0;
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }
    }
}

.CancelBtn {
    @include forMedia(sm) {
        border: 0;
    }
}
