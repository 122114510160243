@import "../../basics-scss/mixins";

.DateTimePickerModal {
  position: absolute;
}

.DateTimePickerInInput {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: $zIndexMiddle;
}

.DateTimePicker {
  position: relative;
  display: block;
  width: 312px;
  background: $colorNeutral000;
  border-radius: 4px;
  box-shadow: $boxShadowModal;
  user-select: none;
}

.Header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 8px;
  text-align: center;
}

.HeaderTitle {
  flex: auto;
}

.Chevron {
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  @include svgIcon(20px,20px,$colorNeutral800);

  &:hover {
    background: $colorNeutral060;
  }
  &:active {
    background: $colorBlue010;
  }
}

.HeaderPopover {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  overflow: hidden;
  background: $colorNeutral000;
  z-index: $zIndexLow;
  &.hidden {
    display: none;
  }
}

.HeaderPopoverTop {
  height: 56px;
  text-align: center;
  line-height: $lineHeightBody;
  padding-top: 22px;
}

.HeaderPopoverCont {
  position: absolute;
  top: 56px;
  left: 0;
  bottom: 16px;
  right: 0;
  overflow-y: auto;
  display: flex;
  @include customScrollbar;
}

.HeaderMonths {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 32px;
  text-align: center;
  width: 100%;
  margin: auto 0;
  padding: 10px 16px;
}

.HeaderYears {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
  width: 100%;
  padding: 0 16px 16px;
}

.HeaderYearsCol {
  padding: 0 0;
}

.HeaderButton {
  display: inline-block;
  vertical-align: top;
  height: 32px;
  width: 100%;
  padding: 0 0;
  color: $colorNeutral800;
  background: $colorNeutral000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:first-letter {
    text-transform: uppercase;
  }
  &:hover {
    background: $colorNeutral060;
  }
  &.active {
    background: $colorBlue010;
  }
}

.HeaderLink {
  display: inline-block;
  vertical-align: top;
  font-size: $fontSizeBody;
  margin-left: 8px;
  @include linkStyles;

  &:first-letter {
    text-transform: uppercase;
  }
  &:first-child {
    margin-left: 0;
  }
}

.HeaderLinkReadonly {
  display: inline-block;
  vertical-align: top;
  font-size: $fontSizeBody;
  margin-left: 8px;
  color: $colorNeutral800;
  &:first-letter {
    text-transform: uppercase;
  }
  &:first-child {
    margin-left: 0;
  }
}

.DaysHeader {
  display: flex;
  font-weight: 600;
  color: $colorNeutral600;
  padding: 0 16px;
  .Col {
    font-size: $fontSizeTitle;
    text-transform: uppercase;
    &:nth-child(6), &:last-child {
      color: $colorRed500;
    }
  }
}

.DaysWrap {
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: $colorNeutral800;
  padding: 0 16px;
}

.Row {
  display: flex;

  &.readonly {
    .ColSelectable {
      cursor: default;
      &:hover {
        background: none;
      }
    }
  }
}

.Col {
  flex: 1;
  height: 32px;
  font-size: $fontSizeBody;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  &:not(.ColOtherMonth){
    //&:nth-child(6), &:last-child {
    //  color: $colorRed500;
    //}
  }
}

.ColSelectable {
  cursor: pointer;

  &:hover {
    background: $colorNeutral060;
  }
}

.ColDisabled {
  cursor: default!important;
  background: none!important;
  border-color: transparent!important;
  color: $colorNeutral090!important;
}

.ColCurrent {
  //font-weight: 600!important;
  //color: $colorNeutral000!important;
  background-color: $colorBlue010!important;
}

.ColOtherMonth {
  color: $colorNeutral090;
  //opacity: .5;
}

.ColPresent {
  //background: $colorNeutral000;
  //color: #0F8AF9;
  //font-weight: 600;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
  }
}

.Timer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border: 1px solid $colorNeutral080;
  border-radius: 4px;
  overflow: hidden;
}

.TimerField {
  width: 30px;
  font-size: $fontSizeBody;
  color: $colorNeutral800;
  position: relative;
  &:first-child {
    &:before {
      display: none;
    }
  }
  &:before {
    content: ':';
    display: block;
    position: absolute;
    left: -2px;
    top: 0;
    line-height: 30px;
  }
  input {
    padding: 0 2px;
    border: 0;
    display: block;
    width: 100%;
    height: 30px;
    text-align: center;
    font-size: $fontSizeInput;
    color: $colorNeutral800;
    box-sizing: border-box;
    -moz-appearance:textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.TimerWrap {
  position: relative;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $colorNeutral080;
}

.BottomSpacer {
  height: 16px;
}

.Control {
  text-align: center;
  padding: 5px 0 15px;
}

.Footer {
  padding: 16px 0;
  text-align: center;
}
.FooterButton {

}
.TodayButton {
  font-size: $fontSizeBody;
  line-height: $lineHeightBody;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  @include linkStyles;
}
