@import "../../../basics-scss/mixins";

.Preview {
    width: 402px;
}
.Field {
    margin-top: 20px;
    &:first-child {
        margin-top: 0;
    }
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}
.FieldName {
    width: 184px;
    padding-right: 8px;
    position: relative;
    float: left;
    word-break: break-word;
    color: $colorNeutral900;
    margin: 0;
}
.FieldContent {
    margin-left: 184px;
}
.Req {
    color: $colorRed500;
    position: absolute;
    top: 0;
    left: -11px;
}
.Text {
    color: $colorNeutral800;
    word-break: break-word;
    &.Hidden{
        color: $colorNeutral090;
    }
}
.BadgeEye {
    display: inline-block;
    vertical-align: -3px;
    margin-right: 8px;
    @include svgIcon(16px,16px,$colorNeutral090);
}
.NotSet {
    color: $colorNeutral090;
}
.LinksList {
    .Link {
        white-space: nowrap;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        @include linkStyles;
    }
    .Item {
        margin-top: 8px;
        &:first-child {
            margin-top: 0;
        }
    }
}

.Image {
    width: 40px;
    height: 40px;
    background: no-repeat center;
    background-size: cover;
    border-radius: 4px;
}
