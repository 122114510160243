@import "../../../basics-scss/mixins";

.HistoryItem {
    padding: 0 16px;
    margin-top: 16px;

    .HistoryHeadBlock {
        display: flex;
        margin-bottom: 8px;
    }

    .HistoryHead {
        color: $colorBlue500;
        font-size: $fontSizeCaption;
        line-height: $lineHeightCaption;
        font-weight: 600;
        padding: 4px;
        border-radius: 4px;
        align-self: center;
        margin-left: auto;
        text-align: right;
        white-space: nowrap;

        .HistoryIconComment {
            display: inline-block;
            vertical-align: top;
            @include svgIcon(16px, 16px, $colorBlue500);
            margin-right: 4px;
            @include forMedia(sm){
                margin-right: 0;
            }
        }

        .HistoryTitle {
            display: inline;
            @include forMedia(sm){
                display: none;
            }
        }
    }

    .HistoryUser {
        display: flex;
        justify-content: flex-start;

        .HistoryContent {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        .HistoryDate {
            font-size: $fontSizeCaption;
            line-height: $lineHeightCaption;
            color: $colorNeutral600;
        }

        .HistoryUserTitle {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $colorNeutral800;
            margin-right: 4px;
        }

        .HistoryUserAvatar {
            width: 32px;
            height: 32px;
            margin-right: 8px;
            border-radius: 32px;

            @include svgIcon(32px, 32px);
        }
    }

    .HistoryItemBlock {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        .HistoryItemNew {
            width: 49%;

            .HistoryNewTitle {
                margin-bottom: 2px;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: $colorNeutral900;
            }

            .DefaultNewText {

            }

            .HistoryNewText {
                font-size: 14px;
                line-height: 20px;
                color: $colorNeutral800;
                word-break: break-word;

                &.HideText {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-height: 63px;
                }
            }

            .TextLink {
                font-size: 14px;
                line-height: 20px;
                margin-top: 8px;
                cursor: pointer;
                color: $colorBlue500;
            }
        }

        .HistoryItemOld {
            width: 49%;
            margin-left: 2%;

            .HistoryOldTitleBlock {
                margin-bottom: 2px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: $colorNeutral090;
            }

            .HistoryOldTitle {

            }

            .IconOld {
                @include svgIcon(18px, 18px, $colorNeutral090);
            }

            .DefaultOldText {

            }

            .HistoryOldText {
                font-size: 14px;
                line-height: 20px;
                color: $colorNeutral090;
                word-break: break-word;

                &.HideText {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-height: 63px;
                }
            }

            .TextLink {
                font-size: 14px;
                line-height: 20px;
                margin-top: 8px;
                cursor: pointer;
                color: $colorBlue500;
            }
        }
    }

    .HistoryChanges {
        max-height: 140px;
        overflow: hidden;
        padding: 0 0 0 40px;
        margin-bottom: 16px;

        &.ShowAll {
            max-height: none;
            &:after {
                display: none;
            }
        }

        &.BigText {
            position: relative;
            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                height: 30%;
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            }
        }
    }

    .BottomLine {
        display: flex;
        justify-content: space-between;
        padding: 0 16px 16px;

        .Date {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.01em;
            color: #6E728F;
        }
    }

    .HistoryShowInfo {
        padding-left: 40px;
    }

    .BottomMargin {
        margin-bottom: 40px;
    }

    .HistoryShowInfoButton {
        font-size: $fontSizeBody;
        line-height: $lineHeightBody;
        color: $colorBlue500;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
    }

    .HistoryIconChevron {
        display: inline-block;
        vertical-align: top;
        @include svgIcon(16px, 16px, $colorBlue500);
        margin-left: 4px;
        position: relative;
        top: 2px;
    }

    .HistoryNoFields {
        font-size: 14px;
        line-height: 20px;
        color: $colorNeutral090;
    }
}
