@import "../../../basics-scss/mixins";

.CategoryList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: -24px;

  @include forMedia(sm){
    margin-right: 0;
  }
}

.CategoryItem {
  width: 50%;
  padding: 0 24px 24px 0;
  position: relative;

  &.hasSub {
    &:before {
      content: '';
      display: block;
      position: absolute;
      background: $colorNeutral000;
      box-shadow: $boxShadowCardTaskboard;
      border-radius: 8px;
      left: 0;
      right: 24px;
      bottom: 16px;
      top: 8px;
    }
  }

  @include forMedia(sm){
    width: 100%;
    padding: 0 0 16px;
    &.hasSub:before {
      right: 0;
      bottom: 8px;
    }
  }

  @include forMedia(xs){
    &.hasSub:before {
      border-radius: 0;
    }
  }
}

.CategoryItemContainer {
  background: $colorNeutral000;
  box-shadow: $boxShadowCardTaskboard;
  border-radius: 8px;
  padding: 24px;
  position: relative;
  height: 100%;
  cursor: pointer;

  &:hover {
    box-shadow: $boxShadowCardHover;
  }

  @include forMedia(sm){
    height: auto;
  }

  @include forMedia(xs){
    padding: 16px;
    border-radius: 0;
  }
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //min-height: 56px;

  @include forMedia(sm){
    //min-height: 24px;
  }
}

.HeaderSubject {
  font-weight: 600;
  font-size: $fontSizeH3;
  line-height: $lineHeightH3;
  color: $colorNeutral900;
  position: relative;
  overflow: hidden;
  flex: auto;
  font-family: $fontFamilyHeadline;

  @include forMedia(xs){
    font-size: $fontSizeH4;
    line-height: $lineHeightH4;
  }
}

.HeaderIcon {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 56px;
  margin-left: 16px;

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }

  @include forMedia(sm){
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
  }
}

.LinkBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-top: 24px;
  @include forMedia(sm){
    margin-top: 16px;
  }
}

.Link {
  font-size: 15px;
  line-height: 24px;
  color: #0050C8;
}

.LinkIcon {
  margin-left: 8px;
  @include svgIcon(24px, 24px, #0050C8);
}
