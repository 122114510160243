@import "../../../basics-scss/mixins";

.menu {
    background: $colorNeutral000;
    border-radius: 4px;
    box-shadow: $boxShadowCard;
    width: 360px;
    z-index: $zIndexContextMenu;
    position: absolute;
    left: 0;
    top: 100%;
    font-size: $fontSizeBody;
    padding: 8px 0;

    @include forMedia(sm){
        width: 310px;
    }
}

.Item {
    position: relative;
    padding: 6px 16px;
    cursor: pointer;
    color: $colorNeutral800;
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-word;
    overflow: hidden;

    & > .menu {
        left: 100%;
        top: 0;
        visibility: hidden;
    }
    &:hover {
        background: $colorNeutral070;
        & > .menu {
            visibility: visible;
        }
    }
}

.ItemText {
    display: flex;
    justify-content: flex-start;
}

.ItemIcon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    position: relative;
    top: 2px;
    @include svgIcon(16px, 16px, $colorNeutral800);
}

.ItemCheck {
    margin-left: 32px;
    @include svgIcon(16px, 16px, $colorGreen500);
}

.Delimeter {
    margin: 8px 16px;
    border-top: 1px solid $colorNeutral080;
}

.GroupHead {
    padding: 4px 16px;
    font-weight: 600;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    text-transform: uppercase;
    color: $colorNeutral090;
}
