@import "../../../basics-scss/mixins";

.User {
    position: relative;
    display: block;
    max-width: 200px;

    &.active {
        .Text {
            color: #E31450;
        }
        .Arrow {
            transform: rotate(180deg);
            @include svgIconColor(#E31450);
        }
    }

    &:hover {
        .Text {
            color: #E31450;
        }
        .Arrow {
            @include svgIconColor(#E31450);
        }
    }
}

.Info {
    white-space: nowrap;
    cursor: pointer;
}

.Ava {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: no-repeat center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    @include svgIcon(32px,32px);

    @include forMedia(sm){
        width: 24px;
        height: 24px;
        @include svgIcon(24px,24px);
    }
}

.AvaWrap {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.Elevate {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    bottom: -2px;
    right: -2px;
    background: $colorNeutral000;
    @include svgIcon(100%,auto,$colorNeutral090);

    &.Active {
        @include svgIconColor($colorBlue300);
    }

    @include forMedia(sm){
        border: 1px solid $colorNeutral000;
        width: 8px;
        height: 8px;
        background: #B6BBC9;
        > * {
            display: none;
        }
        &.Active {
            background: $colorBlue300;
        }
    }
}

.Text {
    display: inline-block;
    vertical-align: top;
    max-width: 144px;
    padding-right: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $colorNeutral800;
    font-size: 16px;
    line-height: 48px;
    position: relative;
    font-weight: 600;

    @include forMedia(sm){
        display: none;
    }
}

.Arrow {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -8px;
    @include svgIcon(16px,16px,$colorNeutral800);
}

.Menu {
    background: $colorNeutral000;
    box-shadow: $boxShadowCard;
    border-radius: 4px;
    width: 280px;
    padding: 8px 0 8px;

    @include forMedia(sm){
        box-shadow: none;
        padding: 0;
        width: 100%;
    }
}

.MenuHeader {
    margin: 0 16px 8px;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    border-bottom: 1px solid $colorNeutral080;
    color: $colorNeutral900;
    overflow: hidden;
    text-overflow: ellipsis;

    @include forMedia(sm){
        display: none;
    }
}

.Element {
    display: flex;
    justify-content: space-between;
    position: relative;
    cursor: pointer;

    &.Active {
        background-color: $colorBlue010;
    }

    &:hover {
        text-decoration: none;
        background-color: $colorNeutral070;
    }

    &:active {
        background-color: $colorBlue010;
    }
}

.ElementTitle {
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    color: $colorNeutral800;
    width: 100%;
    padding: 6px 16px;
    display: flex;
    justify-content: space-between;

    @include forMedia(sm){
        padding: 24px 16px;
        border-bottom: 1px solid #DFE1E5;
    }
}

.ElementIcon {
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    position: relative;
    top: 2px;
    @include svgIcon(16px,16px,$colorNeutral800);
    &.colorGreen {
        @include svgIconColor($colorGreen300);
    }
}

.Freeze {
    overflow: hidden;
}

.Popup {
    z-index: $zIndexModal;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: $colorNeutral000;
}

.PopupHeader {
    padding: 16px;
    border-bottom: 1px solid #DFE1E5;
    position: relative;
}

.PopupHeaderText {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: $fontFamilyHeadline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 38px;
    color: $colorNeutral800;
}

.PopupClose {
    position: absolute;
    top: 16px;
    right: 16px;
    @include svgIcon(24px,24px,$colorNeutral800)
}

.ImpersonateSeparator {
    margin: 8px 16px;
    height: 1px;
    background-color: #e4e9f1;

    @include forMedia(sm){
        display: none;
    }
}

.MobileButton {
    @include svgIcon(24px,24px, $colorNeutral800);
    flex: 0 0 24px;

    &.Active {
        @include svgIconColor(#05C270);
    }
}

.MobilePopup {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $colorNeutral000;
}

.MobileHeader {
    border-bottom: 1px solid $colorNeutral080;
    padding: 20px 16px;
    position: relative;
    display: flex;
    align-items: center;
}

.MobileTitle {
    flex: auto;
    color: $colorNeutral600;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 8px 0 8px;
}