@import "../../../basics-scss/mixins";

.Header {
    background: $colorNeutral000;
    color: $colorNeutral800;
    display: flex;
    align-items: center;
    height: $headerHeight;
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    z-index: $zIndexHeader;
    border-bottom: 1px solid $colorNeutral080;
    @include forMedia(sm){
        height: $headerHeightMobile;
        position: absolute;
    }
}

.Options {
    margin-left: auto;
    margin-right: 16px;
    display: flex;
}

.OptionsIcon {
    @include btnIconCommonStyles;
    border: 1px solid $colorNeutral000;
    background-color: $colorNeutral000;
    @include svgIcon(20px, 20px, $colorNeutral800);

    &:hover {
        background-color: $colorNeutral050;
        border-color: $colorNeutral050;
    }

    &:active {
        background-color: $colorBlue010;
        border-color: $colorBlue010;
    }

    //&:focus {
    //    border-color: $colorBlue150;
    //    box-shadow: inset 0 0 1px 1px $colorBlue150;
    //}

    @include forMedia(sm){
        border: none;
        border-radius: 0;
        width: 24px;
        height: 24px;
        @include svgIcon(24px, 24px);
        &:focus {
            box-shadow: none;
        }
    }
}

.OptionsItem {
    display: flex;
    align-items: center;
    margin-left: 8px;

    @include forMedia(sm){
        margin-left: 18px;
    }

    &.user {
        margin-left: 16px;
        @include forMedia(sm){
            margin-left: 18px;
        }
    }

    &.smHidden {
        @include forMedia(sm){
            display: none;
        }
    }
    &.smOnly {
        display: none;
        @include forMedia(sm){
            display: flex;
        }
    }
}

.Search {
    width: 296px;
    height: 32px;
    border: solid 1px $colorNeutral080;
    border-radius: 4px;
    padding: 0 66px 0 8px;
    font-size: $fontSizeInput;
    @include placeholder();
    @include phLineHeight(30px);
    background: $colorNeutral010;
    @include transition(all);
    color: $colorNeutral800;

    &:hover {
        background-color: $colorNeutral060;
        //border-color: $colorNeutral090;
    }

    &:focus {
        background-color: $colorNeutral000;
        border-color: $colorBlue150;
        box-shadow: inset 0 0 1px 1px $colorBlue150;
    }

    &.opened {
        width: 380px;
    }

    @include forMedia(md) {
        width: 100% !important;
    }
}

.SearchWrap {
    position: relative;
}

.SearchIcon {
    flex: 0 0 16px;
    cursor: pointer;
    @include svgIcon(16px, 16px, $colorNeutral090);

    &:hover {
        @include svgIconColor($colorNeutral800);
    }
}

.SearchIconSplit {
    width: 1px;
    height: 16px;
    background: $colorNeutral080;
    margin: 0 8px;
}

.SearchControls {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.SearchPopup {
    padding: 16px;
    background: $colorNeutral000;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .SearchHeader {
        margin: 0 -16px 16px;
        border-bottom: 1px solid $colorNeutral080;
        padding: 4px 16px 20px;
        align-items: center;
        display: none;

        @include forMedia(sm){
            display: flex;
        }
    }

    .SearchHeadingText {
        color: $colorNeutral600;
        font-size: $fontSizeTitle;
        line-height: $lineHeightTitle;
        font-weight: 600;
        text-transform: uppercase;
    }

    .SearchHeadingClose {
        margin-left: auto;
        @include svgIcon(24px,24px,$colorNeutral800);

    }
}

.Logo {
    display: block;
    margin-left: 16px;

    img {
        max-width: 248px;
        max-height: 32px;
        width: auto;
        height: auto;
        display: block;
    }

    @include forMedia(sm){
        display: none;
    }
}

.Burger {
    display: none;
    @include forMedia(sm){
        display: block;
    }
    margin-left: 18px;
    flex: 0 0 24px;
    width: 24px;
    height: 14px;
    padding: 0;
    position: relative;
    border: none;
    background: none;

    span,
    span:before,
    span:after {
        content: '';
        display: block;
        width: 24px;
        height: 2px;
        background: $colorNeutral800;
        border-radius: 2px;
        position: absolute;
    }

    span {
        top: 50%;
        margin-top: -1px;
        transition: transform 75ms cubic-bezier(.55,.055,.675,.19);
        transition-delay: .12s;
        &:before {
            top: -6px;
            transition: top 75ms ease .12s,opacity 75ms ease;
        }

        &:after {
            bottom: -6px;
            transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
        }
    }



    &.active {
        span {
            transform: rotate(45deg);
            &:before {
                top: 0;
                opacity: 0;
            }
            &:after {
                bottom: 0;
                transform: rotate(-90deg);
            }
        }
    }
}

.BurgerIcon {
    @include svgIcon(24px,24px);
    display: none;
    padding: 0;
    border: 0;
    background: none;
    margin-left: 16px;
    @include forMedia(sm){
        display: block;
    }
}

.Freeze {
    overflow: hidden;
}
