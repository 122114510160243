@import "../../../basics-scss/mixins";

.Sidebar {
    position: fixed;
    left: 0;
    top: $headerHeight;
    bottom: 0;
    width: $sidebarWidth;
    z-index: $zIndexAside;
    @include forMedia(sm){
        top: 0;
        left: -$sidebarWidth;
        z-index: $zIndexModal;
    }
}

.NavHeader {
    border-bottom: 1px solid $colorNeutral080;
    padding: 16px;
    @include forMedia(sm){
        padding: 24px 16px;
    }
}

.NavBody {
    flex: auto;
    position: relative;
    background: $colorNeutral010;
}

.NavContent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: hidden;
    @include customScrollbar;
    -webkit-overflow-scrolling: touch;

    &.NavContentScroll {
        overflow-y: auto;
    }
}

.Nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -350px;
    width: $navWidth;
    background-color: $colorNeutral000;
    border-right: 1px solid $colorNeutral080;
    box-shadow: $boxShadowFixedAside;
    @include transition(left, $transDurationPopup);

    &.active {
        left: $sidebarWidth;
    }

    &.pinned {
        left: $sidebarWidth;
        box-shadow: none;
    }

    @include forMedia(sm){
        width: 100%;
        display: none;
        right: 0;
        left: 0;
        position: fixed;
        transition: none;

        &.active {
            left: 0;
            display: flex;
        }
    }
}

.NavTab {
    display: none;

    &.active {
        display: block;
    }
}

.SearchInput {
    width: 100%;
    height: 32px;
    padding: 0 26px 0 8px;
    font-size: $fontSizeInput;
    color: $colorNeutral800;
    background-color: $colorNeutral010;
    background-image: none;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    -moz-appearance: textfield;
    display: block;
    @include placeholder();
    @include phLineHeight(30px);

    &:hover {
        background-color: $colorNeutral060;
        //border-color: $colorNeutral090;
    }

    &:focus {
        background-color: $colorNeutral000;
        border-color: $colorBlue150;
        box-shadow: inset 0 0 1px 1px $colorBlue150;
    }

}

.Search {
    position: relative;
    flex: auto;
}

.SearchClean {
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    position: absolute;
    @include svgIcon(16px, 16px, $colorNeutral090);

    &:hover {
        @include svgIconColor($colorNeutral800);
    }
}

.SearchWrap {
    display: flex;
}

.PinBtn {
    flex: 0 0 32px;
    margin-left: 8px;

    @include forMedia(sm){
        display: none;
    }
}

.Tabs {
    border-right: 1px solid $colorNeutral080;
    background-color: $colorNeutral000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    @include customScrollbar;
}

.TabsList {
    //padding: 8px 0;
}

.TabsItem {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include svgIcon(24px, 24px, $colorNeutral800);

    &:hover {
        background-color: $colorNeutral070;
    }

    &.active {
        @include svgIconColor($colorBlue500);
    }
}

.MobileTabs {
    display: none;
    @include forMedia(sm){
        display: flex;
    }

    height: 53px;
    margin: 9px -24px -24px;
}

.MobileTabsItem {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid transparent;
    margin-bottom: -1px;
    cursor: pointer;
    @include svgIcon(24px, 24px, $colorNeutral800);

    &.active {
        border-color: $colorBlue500;
        @include svgIconColor($colorBlue500);
    }
}

.MobileTop {
    display: none;
    border-bottom: 1px solid $colorNeutral080;
    height: $headerHeightMobile;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    @include forMedia(sm){
        display: flex;
    }
}

.MobileLogo {
    img {
        max-width: 230px;
        max-height: 32px;
        width: auto;
        height: auto;
        display: block;
    }
}

.SkeletonTabIcon {
    height: 24px;
    width: 24px;
    background: $colorNeutral050;
    border-radius: 50%;
}

.SkeletonShadow {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    &:after {
        @include animatedShadow(1.2s, 88px);
    }
}

.SkeletonTab {
    cursor: default !important;

    &:hover {
        background: transparent !important;
    }
}

.Freeze {
    overflow: hidden;
}

.Loader {
    position: absolute;
    left: 50%;
    top: 50%;
    animation: LoaderFadeIn .1s ease-in;
    margin: -32px 0 0 -32px;
    width: 64px;
    height: 64px;
    background: url("/assets/img/page-loader.gif") no-repeat 0 0;
    background-size: 100% 100%;
}

.SkeletonList {
    padding: 10px 16px;
    position: relative;
    overflow: hidden;
    &:after {
        @include animatedShadow(1.2s, 88px);
    }
}

.SkeletonListItem {
    margin-bottom: 20px;
    display: flex;
}

.SkeletonTitleHolder {
    border-radius: 4px;
    height: 20px;
    background: $colorNeutral077;
    width: 100%;
}

.SkeletonListHolder {
    border-radius: 4px;
    height: 20px;
    background: $colorNeutral077;
    &:first-child {
        flex: 0 0 20px;
        margin-right: 4px;
    }
}

.SkeletonDynamicHeader {
    margin-bottom: 8px;
    width: 85.5%;
}

.DynamicHeader {
    display: flex;
    margin-bottom: 8px;
    width: 83.5%;
}

.DynamicHeaderRow {
    margin-right: 6px;
    @include svgIcon(16px, 16px, $colorNeutral800);
    cursor: pointer;

    &:hover {
        background: $colorNeutral070;
        border-radius: 4px;
    }
}

.DynamicHeaderTitle {
    position: relative;
    width: 100%;
    font-size: $fontSizeH4;
    line-height: $lineHeightH4;
    color: $colorNeutral900;
    font-family: $fontFamilyMain;
    font-weight: 600;
}
