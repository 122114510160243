@import "../../basics-scss/mixins";

.form {
    border-radius: 8px;
    background-color: $colorNeutral000;
    width: 496px;
    position: relative;
    margin: 8px;
    @include forMedia(xs) {
        width: 100%;
    }
}

.container {
    min-height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include forMedia(xs) {
        min-height: 370px;
    }
}

.logo {
    display: block;
    text-align: center;
    line-height: 0;
    margin-bottom: 32px;

    img {
        max-width: 272px;
        max-height: 56px;
        width: auto;
        height: auto;
    }

    @include forMedia(xs) {
        margin-bottom: 24px;
        img {
            max-width: 192px;
            max-height: 32px;
        }
    }
}

.enterBtn {
    margin: 0 auto;
    min-width: 184px;
}

.title {
    font-family: $fontFamilyHeadline;
    font-weight: 600;
    font-size: $fontSizeH3;
    line-height: $lineHeightH3;
    text-align: center;
    color: $colorNeutral900;
}
