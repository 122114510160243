@import "../../basics-scss/mixins";

.preloader {
  position: relative;
  background: $colorNeutral000 url("/assets/img/page-loader.gif") no-repeat center;
  background-size: 64px 64px;
  height: 100px;
}

.popUp {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $colorOverlay;

  @include forMedia(sm){
    background-color: $colorNeutral000;
    display: block;
  }
}

.popUpOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.popUpContainer {
  position: relative;
  width: 512px;
  height: 328px;
  border-radius: 4px;
  box-shadow: $boxShadowModal;
  background-color: $colorNeutral000;

  @include forMedia(sm){
    width: 100%;
    height: auto;
    box-shadow: none;
  }
}

.header {
  padding: 24px 24px;
  position: relative;

  @include forMedia(sm){
    padding: 16px 16px;
    border-bottom: 1px solid $colorNeutral080;
    display: flex;
    align-items: center;
  }
}

.headerText {
  font-size: $fontSizeH2;
  line-height: $lineHeightH2;
  color: $colorNeutral900;
  font-family: $fontFamilyHeadline;
  font-weight: 600;
  padding-right: 32px;

  @include forMedia(sm){
    text-transform: uppercase;
    font-size: $fontSizeTitle;
    line-height: $lineHeightTitle;
    color: $colorNeutral600;
    font-family: $fontFamilyMain;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 0;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;

  @include forMedia(sm){
    position: static;
    margin-left: auto;
    flex: 0 0 32px;
  }
}


.content {
  padding: 0 24px 20px;

  @include forMedia(sm){
    padding: 16px;
  }
}

.contentContainer {
  max-height: 160px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: $boxShadowModal;
  background-color: $colorNeutral000;
  @include customScrollbar;
}

.userContainer {
  color: $colorNeutral800;
  padding: 0 12px;
  height: 26px;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    background-color: #e8f0fe;
  }
}

.userName, .userLogin  {
  padding: 1px 24px;
}

.searchContainer {
  position: relative;
  margin-bottom: 4px;
}

.searchInput {
  height: 32px;
}

.message {
  text-align: center;
  margin-top: 32px;
}

.searchIcon {
  position: absolute;
  right: 32px;
  top: 50%;
  line-height: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  @include svgIcon(18px,18px,$colorNeutral800);
}
