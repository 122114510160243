@import "../../../basics-scss/mixins";

.Search {
    margin: auto;
}

.SearchWrapper {
    position: relative;
    margin: 0 auto;
    height: 56px;
    width: 100%;
}

.SearchContainer {
    position: absolute;
    border-radius: 4px;
    box-shadow: $boxShadowCardTaskboard;
    background-color: $colorNeutral000;
    width: 100%;
    z-index: $zIndexHigh;
    &:hover {
        box-shadow: $boxShadowCardHover;
    }
    &.focus {
        box-shadow: $boxShadowCardHover;
    }
    @include forMedia(xs) {
        border-radius: 0;
    }
}

.Heading {
    text-align: center;
    color: $colorNeutral900;
    line-height: $lineHeightH1;
    font-weight: 600;
    font-size: $fontSizeH1;
    font-family: $fontFamilyHeadline;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    @include forMedia(xs){
        font-size: $fontSizeH3;
        line-height: $lineHeightH3;
        padding: 0 16px;
    }
}

.Line {
    margin: 0 16px;
    height: 1px;
    background-color: $colorNeutral080;
}

.GroupsBlock {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px 16px 0 16px;
    -webkit-overflow-scrolling: touch;
    @include customScrollbar;
}

.Group {
    margin-top: 24px;
    &:first-child {
        margin-top: 0;
    }
}

.GroupTitle {
    margin-bottom: 8px;
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    font-family: $fontFamilyMain;
    font-weight: 600;
    color: $colorNeutral900;
}

.Divider {
    border-top: 1px solid $colorNeutral080;
    margin-bottom: 16px;
}

.Item {
    min-height: 32px;
    margin: 0 -16px;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        background-color: $colorNeutral070;
        border-color: $colorNeutral070;
    }

    &:active {
        background-color: $colorBlue010;
        border-color: $colorBlue010;
    }
}

.ItemLink {
    display: block;
    padding: 6px 16px;
    @include clearFix;
}

.ItemIcon {
    display: block;
    float: left;
    @include svgIcon(20px, 20px, $colorBlue500);
    user-select: none;
    cursor: pointer;
}

.ItemTitle {
    font-size: $fontSizeLabel;
    font-family: $fontFamilyMain;
    line-height: $lineHeightLabel;
    color: $colorNeutral800;
    user-select: none;
    cursor: pointer;
    display: block;
    overflow: hidden;
    margin-left: 24px;
}

.LargeInputContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    padding: 16px;
}

.LargeInput {
    margin: 0 16px 0 0;
    padding: 0;
    border: 0;
    width: 100%;
    background-color: $colorNeutral000;
    color: $colorNeutral800;
    font-size: 16px;
    font-family: $fontFamilyMain;
    line-height: 24px;
    @include placeholder($colorNeutral080);
}

.LargeInputIcon {
    @include svgIcon(24px, 24px, $colorNeutral080);
    user-select: none;

    &.focus {
        cursor: pointer;
        @include svgIconColor($colorNeutral800);
    }
}

.SmallInputContainer {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    padding: 5px 8px;
    border-radius: 4px;
    border: solid 1px $colorNeutral080;
    background-color: $colorNeutral010;

    &.focus {
        border-color: $colorBlue150;
        box-shadow: inset 0 0 1px 1px $colorBlue150;
    }
}

.SmallInput {
    margin: 0 16px 0 0;
    padding: 0;
    width: 100%;
    height: 20px;
    background-color: $colorNeutral010;
    border: 0;
    font-size: $fontSizeInput;
    font-family: $fontFamilyMain;
    color: $colorNeutral800;
    @include placeholder($colorNeutral090);
}

.SmallInputIcon {
    @include svgIcon(16px, 16px, $colorNeutral090);
    user-select: none;
    padding: 2px 0;
    &.focus {
        cursor: pointer;

        &:hover {
            @include svgIconColor($colorNeutral800);
        }
    }
}

.Delimiter {
    height: 24px;
    margin: 0 8px;
    width: 1px;
    background-color: $colorNeutral080;
}

.Freeze {
    overflow: hidden;
}


.PopupShow {
    width: 24px;
    height: 24px;
    @include svgIcon(24px,24px,$colorNeutral800);
}

.Popup {
    z-index: $zIndexModal;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: $colorNeutral000;
    padding: 0 16px;
}

.PopupHeader {
    display: flex;
    border-bottom: 1px solid $colorNeutral080;
    padding: 16px;
    align-items: center;
    margin: 0 -16px 12px;

    .PopupHeading {
        font-family: $fontFamilyHeadline;
        color: $colorNeutral800;
        font-weight: 600;
        font-size: $fontSizeH3;
        line-height: $lineHeightH3
    }
}

.PopupClose {
    margin-left: auto;

    @include svgIcon(24px,24px,$colorNeutral800);
}

.InputSmWrap {
    width: 100%;
    position: relative;
}

.InputSmDropDown {
    background: $colorNeutral000;
    box-shadow: $boxShadowCardTaskboard;
    border-radius: 8px;
    z-index: $zIndexHigh;
    position: absolute;
    left: 0;
    width: 100%;
    top: 100%;
    margin-top: 4px;
    overflow: hidden;
}

.InputSmDropCont {
    padding: 12px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    @include customScrollbar;
}
