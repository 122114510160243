@import "../../basics-scss/mixins";

.Popup {
    position: absolute;
    background: $colorNeutral000;
    border-radius: 4px;
    box-shadow: $boxShadowCard;
    z-index: $zIndexPopover;

    &.animated {
        @include animateFadeIn();
    }
}

.PopupTriangle {
    position: absolute;
    z-index: $zIndexPopover;
    @include tooltip-triangle('left');

    &.animated {
        @include animateFadeIn();
    }
}

.PopupTitle {
    margin: 0;
    padding: 12px 24px;
    background-color: $colorNeutral010;
    border-bottom: 1px solid $colorNeutral080;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.PopupText {
    padding: 0 14px 0 0;
    font-weight: 600;
    font-size: 14px;
    color: $colorNeutral900;
    max-width: 306px;
}

.PopupLink {

}

.PopupContent {
    padding: 24px;
    max-height: 530px;
    overflow-y: auto;
    border-radius: 0 0 4px 4px;
    @include customScrollbar;
}

.preloader {
    position: relative;
    background: $colorNeutral000 url("/assets/img/page-loader.gif") no-repeat center;
    background-size: 64px 64px;
    height: 200px;
}
