@import "../../../../../basics-scss/mixins";

.Search {

}

.InputWrap {
  position: relative;
}

.Input{
  width: 100%;
  height: 32px;
  padding: 0 31px 0 8px;
  font-size: $fontSizeInput;
  color: $colorNeutral800;
  background: $colorNeutral010;
  border: 1px solid $colorNeutral080;
  border-radius: 4px;
  text-align: left;
  cursor: default;
  line-height: 32px;

  &:hover {
    background-color: $colorNeutral060;
  }

  &:focus {
    background-color: $colorNeutral000;
    border-color: $colorBlue150;
    box-shadow: inset 0 0 1px 1px $colorBlue150;
  }

  @include placeholder();
  @include phLineHeight(32px);
}

.Icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  @include svgIcon(16px,16px,$colorNeutral090);
}

.CloseIcon {
  cursor: pointer;
}
