@import "../../basics-scss/mixins";

.WrapLoader {
    height: 100vh;
    width: 100vw;
    background: $colorNeutral000;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapper {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: $colorNeutral010;
}

.main {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
}

.contacts {
    text-align: center;
    padding: 18px 0 26px;
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
    color: $colorBlue500;
}

.contactsItem {
    display: inline-block;
    vertical-align: top;
    margin: 0 8px;
}

.form {
    border-radius: 8px;
    background: $colorNeutral010;
    width: 496px;
    position: relative;
    border: 1px solid $colorNeutral080;
    @include forMedia(xs) {
        width: 100%;
    }
}

.container {
    padding: 60px 32px;
    min-height: 408px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include forMedia(xs) {
        padding: 60px 16px;
        min-height: 370px;
    }
}

.logo {
    display: block;
    text-align: center;
    line-height: 0;
    margin-bottom: 32px;

    img {
        max-width: 272px;
        max-height: 56px;
        width: auto;
        height: auto;
    }

    @include forMedia(xs) {
        margin-bottom: 24px;
        img {
            max-width: 192px;
            max-height: 32px;
        }
    }
}

.title {
    font-family: $fontFamilyHeadline;
    font-weight: 600;
    font-size: $fontSizeH3;
    line-height: $lineHeightH3;
    text-align: center;
    color: $colorNeutral900;
}

.text {
    font-size: 16px;
    line-height: 24px;
    color: $colorNeutral600;
    margin-top: 8px;
    text-align: center;
}

.buttonWrap {
    margin-top: 34px;
    text-align: center;
}

.info {
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
    color: $colorNeutral600;
    position: absolute;
    top: 33px;
    right: 24px;
    text-align: right;
    max-width: calc(50% - 20px);
    @include forMedia(xs){
        top: 23px;
        right: 20px;
    }
}

.infoIcon {
    display: inline-block;
    vertical-align: top;
    margin-right: 4px;
    @include svgIcon(16px,16px,$colorGreen500);
}