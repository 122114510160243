@import "../../../../../basics-scss/mixins";

.input_container {
    flex: 1;
    textarea {
        width: 100%;
        padding: 5px 8px 7px;
        line-height: $lineHeightBody;
        resize: none;
        font-size: $fontSizeInput;
        color: $colorNeutral800;
        background-color: $colorNeutral010;
        background-image: none;
        border: 1px solid $colorNeutral080;
        border-radius: 4px;
        overflow-y: hidden;
        min-height: 50px;
        @include customScrollbar;
        @include placeholder;
        @include transition();

        &:hover {
            background-color: $colorNeutral060;
            //border-color: $colorNeutral090;
        }

        &:focus {
            background-color: $colorNeutral000;
            border-color: $colorBlue150;
            box-shadow: inset 0 0 1px 1px $colorBlue150;
        }

        &[disabled],
        &[readonly] {
            @include readonlyInputStyles;
        }
    }
    &.warningInput {
        textarea {
            border-color: $colorRed300;
        }
    }
}

.textContainer {
    position: absolute;
    right: 100%;
    bottom: 100%;
    word-wrap: break-word;
    overflow: hidden;
    visibility: hidden;
}
