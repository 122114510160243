@import "../../../../basics-scss/mixins";

.ListSorting {

}

.ListSortingItem {
  font-weight: 600;
  font-size: $fontSizeLabel;
  line-height: $lineHeightLabel;
  padding: 16px 24px;
  white-space: nowrap;
  color: $colorNeutral600;

  & > div {
    display: inline-block;
    vertical-align: top;
  }
}

.Title {
  cursor: pointer;
}

.Icon {
  margin-left: 5px;
  position: relative;
  top: 3px;
  @include svgIcon(14px, 14px, #6E728F);
}
