@import "../../../basics-scss/mixins";

.Lanes {
    display: flex;
    justify-content: flex-start;
    align-items: start;
}

.Lane {
    padding: 16px;
    width: 384px;

    &.LaneOnEnter {
        background: #FCFCFD;
        border: 1px solid #D5D8DD;
        border-radius: 16px;

        div {
            visibility: hidden;
        }
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.LaneHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .LineTitleBlock {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .LineTitle {
            color: #0B0C0E;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
        }

        .LineCount {
            margin-left: 8px;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #ABB1BA;
        }
    }

    .LineButtonsBlock {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &.LaneButton {
            @include svgIcon(24px, 24px, #2E3238)
        }

        &.DisabledLineButton {
            @include svgIcon(24px, 24px, $colorNeutral090)

        }
    }
}

.LaneCards {
    margin: 16px 0;
    width: 352px;
}
