@import "../../basics-scss/mixins";

.EditorContainer {
    margin-bottom: 16px;
    color: $colorNeutral800;
    position: relative;
    line-height: 20px;
    font-weight: 400;
    text-align: left;

    .Placeholder {
        color: $colorNeutral090;
        position: absolute;
        top: 8px;
        left: 8px;
        font-size: $fontSizeInput;
        user-select: none;
        display: inline-block;
        pointer-events: none;
    }

    &:hover {
        .EditorInner {
            background: #F0F2F4;
        }
    }

    .EditorInner {
        position: relative;
        background: $colorNeutral010;
        border: 1px solid $colorNeutral080;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        max-height: 582px;
        min-height: 62px;
        overflow: auto;
        transition: background-color .1s ease-out,border-color .1s ease-out;
        @include customScrollbar;

        &:focus-within {
            border: 2px solid $colorBlue150;
            background: $colorNeutral000;

            .EditorInput {
                padding: 7px 7px 13px;
            }

            .Placeholder {
                position: absolute;
                top: 7px;
                left: 7px;
            }
        }

        @include forMedia(sm) {
            max-height: 282px;
        }
    }

    .EditorInput {
        min-height: 58px;
        font-size: 15px;
        position: relative;
        tab-size: 1;
        outline: 0;
        padding: 8px 8px 14px;
        caret-color: #444;
    }
}

.DisabledEditor {
    border: none;

    .EditorInner {
        border-radius: 4px;
        background: $colorNeutral040 !important;
    }

    &:hover {
        background: $colorNeutral040;
    }
}