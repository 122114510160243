@import "../../../../../basics-scss/mixins";

.ListWrap {
    flex: 1;
    position: relative;
}


.List {
    display: flex;
    margin-bottom: 10px;

    .Select {
        flex: auto;

        select {
            height: 100%;
        }
    }

    .Controls {
        margin-left: 2px;
        display: flex;
        flex-direction: column;

        & > * {
            margin-top: 2px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    select {
        width: auto;
        min-width: 100%;
    }
}

.StaticList {
    padding: 3px 0 3px 8px;
    line-height: 1.4;
    border-radius: 4px;
    border: 1px solid $colorNeutral040;
    @include readonlyInputStyles();
    min-height: 32px;
}

.Field {
    position: relative;
    padding: 3px 44px 3px 8px;
    background: $colorNeutral010;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    margin-right: 36px;
    display: block;
    min-height: 32px;
    @include transition();

    &.CanCreate {
        margin-right: 72px;
    }

    &.hovered {
        background-color: $colorNeutral060;
        //border-color: $colorNeutral090;
    }

    &.focused {
        background-color: $colorNeutral000;
        border-color: $colorBlue150;
        box-shadow: inset 0 0 1px 1px $colorBlue150;
    }

    &.isWindow {
        margin-right: 0!important;
    }
}

.FieldOverlay {
    cursor: text;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.FocusOverlay {
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;

    &:focus {
        border-color: $colorBlue150!important;
        box-shadow: inset 0 0 1px 1px $colorBlue150!important;
    }
}

.FieldClear {
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 7px;
    @include svgIcon(16px,16px,$colorNeutral800);
}

.FieldSearch {
    position: absolute;
    right: 8px;
    top: 6px;
    @include svgIcon(18px,18px,$colorNeutral800);
}

.Link {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 0 10px 5px 0;
    color: $colorBlue500;
}

.JustText {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 0 10px 5px 0;
}

.Content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    min-height: 32px;
}
