@import "variables/index";

// clearFix
@mixin clearFix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

// for media queries
@mixin forMedia($size) {
    @if $size == xs {
        @media (max-width: 575px) { @content; }
    } @else if $size == sm {
        @media (max-width: 767px) { @content; }
    } @else if $size == md {
        @media (max-width: 991px) { @content; }
    } @else if $size == lg {
        @media (max-width: 1199px) { @content; }
    } @else  {
        @media (max-width: $size) { @content; }
    }
}

// scrollbar styles
@mixin customScrollbar() {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $colorNeutral080;
        border-radius: 16px;
    }
}

// svg icon styles
@mixin svgIcon($width: 24px, $height: 24px, $color: null) {
    svg {
        display: block; // to prevent line-height influence
        width: $width;
        height: $height;
        @if $color {
            path {
                fill: $color;
            }
        }
    }
}

// svg icon color only. can be used for :hover, :active etc.
@mixin svgIconColor($color: $colorNeutral800) {
    svg {
        path {
            fill: $color;
        }
    }
}

// input placeholder color
@mixin placeholder($color: $colorNeutral090) {
    &::-webkit-input-placeholder {color: $color}
    &:-moz-placeholder           {color: $color}
    &::-moz-placeholder          {color: $color; opacity: 1}
    &:-ms-input-placeholder      {color: $color}
}

// firefox input placeholder vertical position fix
@mixin phLineHeight($line-height) {
    &:-moz-placeholder           {
        line-height: $line-height;
    }
    &::-moz-placeholder          {
        line-height: $line-height;
    }
}

// animated shadow
@mixin animatedShadow($speed: .8s, $width: 44px) {
    animation: animateShadow $speed ease-in infinite;
    content: '';
    display: block;
    position: absolute;
    top: -50px;
    bottom: -50px;
    left: -100%;
    width: $width;
    opacity: .5;
    transform: rotate(10deg) translateX(-50%);
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );

    @keyframes animateShadow {
        from {
            left: -100%;
        }

        to {
            left: 100%;
        }
    }
}

// dropdown style
@mixin dropDown() {
    border-radius: 4px;
    box-shadow: $boxShadowModal;
    background-color: $colorNeutral000;
}

// button
@mixin btnCommonStyles() {
    font-weight: 600;
    font-size: $fontSizeButton;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    border-radius: 4px;
    padding: 0 12px;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    cursor: pointer;
    border: 1px solid;
    height: 32px;
    @include transition();

    &:focus:not(:active) {
        border-color: $colorBlue150!important;
        box-shadow: inset 0 0 1px 1px $colorBlue150!important;
    }

    &[disabled] {
        &,
        &:hover,
        &:focus,
        &:active {
            background-color: $colorNeutral080;
            border-color: $colorNeutral080;
            color: $colorNeutral090;
            cursor: default;
            box-shadow: none;
            @include svgIconColor($colorNeutral090!important);
        }
    }

    &[data-loader='true'] {
        &,
        &:hover,
        &:focus,
        &:active {
            overflow: hidden;
            position: relative;
            background-color: $colorNeutral080;
            border-color: $colorNeutral080;
            color: $colorNeutral600;
            cursor: default;
            box-shadow: none;
            @include svgIconColor($colorNeutral600!important);

            &:after {
                @include animatedShadow;
            }
        }
    }
}

// button-icon
@mixin btnIconCommonStyles() {
    width: 32px;
    height: 32px;
    line-height: 1;
    display: inline-flex;
    vertical-align: top;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
    padding: 0;
    @include transition();

    &:focus:not(:active) {
        border-color: $colorBlue150!important;
        box-shadow: inset 0 0 1px 1px $colorBlue150!important;
    }

    &[readonly],
    &[disabled] {
        &,
        &:focus,
        &:hover,
        &:active {
            cursor: default;
            border-color: $colorNeutral080!important;
            background-color: $colorNeutral080!important;
            @include svgIconColor($colorNeutral090!important);
        }
    }

    &[data-loader='true'] {
        &,
        &:hover,
        &:focus,
        &:active {
            overflow: hidden;
            position: relative;
            background-color: $colorNeutral080;
            border-color: $colorNeutral080;
            color: $colorNeutral600;
            cursor: default;
            box-shadow: none;
            @include svgIconColor($colorNeutral600!important);

            &:after {
                @include animatedShadow;
            }
        }
    }
}

// form label
@mixin formLabel() {
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    font-weight: 600;
    color: $colorNeutral900;
    margin-bottom: 8px;
}

// readonly common styles
@mixin readonlyInputStyles() {
    cursor: default;
    background-color: $colorNeutral040;
    border-color: $colorNeutral040;
    color: $colorNeutral800;
    box-shadow: none;

    &:focus {
        border-color: $colorBlue150;
        box-shadow: inset 0 0 1px 1px $colorBlue150;
    }
}

@mixin stringInputStyles() {
    width: 100%;
    height: 32px;
    padding: 0 8px;
    font-size: $fontSizeInput;
    color: $colorNeutral800;
    background-color: $colorNeutral010;
    background-image: none;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    display: block;
    -moz-appearance: textfield;
    @include placeholder();
    @include phLineHeight(30px);
    @include transition();

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:hover {
        background-color: $colorNeutral060;
    }

    &:focus {
        background-color: $colorNeutral000;
        border-color: $colorBlue150;
        box-shadow: inset 0 0 1px 1px $colorBlue150;
    }

    &[disabled],
    &[readonly] {
        @include readonlyInputStyles;
    }
}

// link common styles
@mixin linkStyles() {
    color: $colorBlue300;
    cursor: pointer;

    &:visited {
        color: $colorBlue500;
    }
    &:hover {
        text-decoration: underline;
    }
    &:focus {
        text-decoration: underline;
    }
    &:active {
        color: $colorNeutral600;
    }
}

@mixin transition($property: null, $duration: $transDuration, $timing: $transTiming) {
    @if $property {
        transition: $property $duration $timing;
    }
    @else {
        transition: background-color $duration $timing, border-color $duration $timing;
    }
}

@mixin animateFadeIn($duration: $transDuration, $timing: $transTiming) {
    animation: fadeIn $duration $timing;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

@mixin animateMoveIn($duration: $transDuration, $timing: $transTiming) {
    animation: moveIn $duration $timing;

    @keyframes moveIn {
        from {
            transform: translateY(30px) scale(0.9);
        }
        to {
            transform: translateY(0) scale(1);
        }
    }
}

// tooltip triangle
@mixin tooltip-triangle($direction) {
    $shadowColor: $colorNeutral800;
    $color: $colorNeutral000;
    $height: 24px;
    $width: 8px;

    @if ($direction == 'right' OR $direction == 'left') {
        height: $height;
        width: $width;
    } @else {
        height: $width;
        width: $height;
    }

    &:before {
        content: '';
        border-style: solid;
        height: 0;
        width: 0;
        position: absolute;
        left: 0;
        top: 0;
        opacity: .1;
    }

    &:after {
        content: '';
        border-style: solid;
        height: 0;
        width: 0;
        position: absolute;
    }

    @if($direction == 'top') {
        &:after {
            border-color: transparent transparent $color transparent;
            border-width: 0 ($height/2) $width ($height/2);
        }
        &:before {
            border-color: transparent transparent $shadowColor transparent;
            border-width: 0 ($height/2) $width ($height/2);
            top: -1px;
        }
    }
    @if ($direction == 'bottom') {
        &:after {
            border-color: $color transparent transparent transparent;
            border-width: $width ($height/2) 0 ($height/2);
        }
        &:before {
            border-color: $shadowColor transparent transparent transparent;
            border-width: $width ($height/2) 0 ($height/2);
            top: 1px;
        }
    }
    @if ($direction == 'right') {
        &:after {
            border-color: transparent transparent transparent $color;
            border-width: ($height/2) 0 ($height/2) $width;
        }
        &:before {
            border-color: transparent transparent transparent $shadowColor;
            border-width: ($height/2) 0 ($height/2) $width;
            left: 1px;
        }
    }
    @if ($direction == 'left') {
        &:after {
            border-color: transparent $color transparent transparent;
            border-width: ($height/2) $width ($height/2) 0;
        }
        &:before {
            border-color: transparent $shadowColor transparent transparent;
            border-width: ($height/2) $width ($height/2) 0;
            left: -1px;
        }
    }
}
