@import "../../../basics-scss/mixins";

.Menu {
    background: $colorNeutral000;
    box-shadow: $boxShadowCard;
    border-radius: 4px;
    min-width: 224px;
    padding: 8px 0;
    max-width: 464px;

    &.Active {
        display: block;
    }

    .Section {
        border-top: 1px solid #DFE1E5;
        padding: 8px 0;

        &:first-child {
            border-top: 0;
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    .Element {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 6px 0;
        line-height: $lineHeightBody;
        cursor: pointer;

        &.Active {
            background-color: $colorBlue010;
        }

        .ElementTitle {
            font-size: $fontSizeBody;
            color: $colorNeutral800;
            width: 100%;
            padding: 0 16px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:hover {
            text-decoration: none;
            background-color: $colorNeutral070;
        }

        &:active {
            background-color: $colorBlue010;
        }

        .Arrow {
            padding: 2px 8px 0 0;

            @include svgIcon(16px,16px);
        }
    }
}

.InlineBlock {
    display: inline-block;
    vertical-align: top;
}
