@import "../../basics-scss/mixins";

.ContainerWindow {
    padding-top: 20px;
    padding-bottom: 8px;
    @include forMedia(sm){
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.AddBlock {
    padding-bottom: 36px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.AddHeader {
    margin-right: 16px;
    font-family: $fontFamilyHeadline;
    color: $colorNeutral800;
    font-size: $fontSizeH3;
    line-height: $lineHeightH2;
}

.FilterWrap {
    margin-top: -16px;
    @include forMedia(sm){
        margin-top: 0;
    }
}