@import "../../basics-scss/mixins";

.Default {
    @include btnCommonStyles;
    color: $colorNeutral800;
    border-color: $colorNeutral080;
    background-color: $colorNeutral000;

    &:hover {
        background-color: $colorNeutral050;
    }

    &:active {
        border-color: $colorNeutral090;
        background-color: $colorNeutral080;
    }
}

.Secondary {
    @include btnCommonStyles;
    color: $colorNeutral800;
    border-color: transparent;
    background-color: transparent;

    &:hover {
        border-color: $colorNeutral050;
        background-color: $colorNeutral050;
    }

    &:active {
        border-color: $colorBlue010;
        background-color: $colorBlue010;
    }
}

.Primary {
    @include btnCommonStyles;
    color: $colorNeutral000;
    border-color: $colorBlue300;
    background-color: $colorBlue300;

    &:hover {
        border-color: $colorBlue400;
        background-color: $colorBlue400;
    }

    &:active {
        border-color: $colorBlue500;
        background-color: $colorBlue500;
    }

    .TextIcon {
        @include svgIconColor($colorNeutral000);
    }

}

.TextIcon {
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    position: relative;
    top: 5px;
    @include svgIcon(20px,20px);
}

.Destructive {
    @include btnCommonStyles;
    color: $colorNeutral000;
    border-color: $colorRed300;
    background-color: $colorRed300;

    &:hover {
        border-color: $colorRed400;
        background-color: $colorRed400;
    }

    &:active {
        border-color: $colorRed500;
        background-color: $colorRed500;
    }

}

.Approve {
    @include btnCommonStyles;
    color: $colorNeutral000;
    border-color: $colorGreen300;
    background-color: $colorGreen300;

    &:hover {
        border-color: $colorGreen400;
        background-color: $colorGreen400;
    }

    &:active {
        border-color: $colorGreen500;
        background-color: $colorGreen500;
    }
}

.Icon {
    @include btnIconCommonStyles;
    border: 1px solid transparent;
    background-color: transparent;
    @include svgIcon(20px, 20px, $colorNeutral800);

    &:hover {
        background-color: $colorNeutral070;
        border-color: $colorNeutral070;
    }

    &:active {
        background-color: $colorBlue010;
        border-color: $colorBlue010;
    }

}

.IconPrimary {
    @include btnIconCommonStyles;
    background-color: $colorBlue300;
    border: 1px solid $colorBlue300;
    @include svgIcon(20px, 20px, $colorNeutral000);

    &:hover {
        background-color: $colorBlue400;
        border-color: $colorBlue400;
    }

    &:active {
        background-color: $colorBlue500;
        border-color: $colorBlue500;
    }

}

.IconMini {
    @include btnIconCommonStyles;
    width: 20px;
    height: 20px;

    border: 1px solid transparent;
    background-color: transparent;
    @include svgIcon(16px, 16px, $colorNeutral800);

    &:hover {
        background-color: $colorNeutral070;
        border-color: $colorNeutral070;
    }

    &:active {
        background-color: $colorBlue010;
        border-color: $colorBlue010;
    }

}

.IconBorder {
    @include btnIconCommonStyles;
    border: 1px solid $colorNeutral080;
    background-color: $colorNeutral000;
    @include svgIcon(20px, 20px, $colorNeutral800);

    &:hover {
        background-color: $colorNeutral050;
    }

    &:active {
        background-color: $colorNeutral080;
        border-color: $colorNeutral090;
    }

}

.Attach {
    @extend .Icon;
    position: relative;

    .attachmentsCount {
        position: absolute;
        min-width: 16px;
        height: 16px;
        color: $colorNeutral000;
        background: $colorBlue300;
        font-weight: 600;
        text-align: center;
        border-radius: 50%;
        font-size: 9px;
        line-height: 16px;
        right: 0;
        top: 0;
    }
}

.SizeSmall {
    font-size: $fontSizeCaption;
    padding: 0 8px;
    height: 24px;
    line-height: 22px;
}

@keyframes Rotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.Expand {
    @include btnCommonStyles;
    border: 1px solid $colorNeutral020;
    background-color: $colorNeutral020;
    color: $colorBlue300;
    height: 24px;
    line-height: 22px;
    padding: 0 8px;


    &:hover {
        background-color: $colorBlue070;
        border-color: $colorBlue070;
    }

    &:active {
        background-color: $colorBlue100;
        border-color: $colorBlue100;
    }

    .TextIcon {
        @include svgIcon(16px, 16px, $colorBlue300);
        width: 16px;
        height: 16px;
        margin-right: 4px;
        top: 4px;
    }
}