@import "../../../basics-scss/mixins";

.AllItems {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: $colorNeutral800;

  @include forMedia(sm){
    padding: 0 16px;
  }
}

.List {
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  text-align: left;
}

.ListWrap {
  overflow: hidden;
  background: $colorNeutral000;
  border-radius: 8px;
  box-shadow: $boxShadowCardTaskboard;

  @include forMedia(xs){
    border-radius: 0;
  }
}

.ListContainer {
  overflow-x: auto;
}

.ListScrollbar {
  z-index: $zIndexFixedPanel;
  bottom: 0;
  visibility: hidden;
  position: fixed;
  overflow-x: auto;

  div {
    height: 1px;
  }

  &.active {
    visibility: visible;
  }
}

.ListRow {
  .ListGradient {
    background: linear-gradient(to bottom, rgba(251, 252, 253, 0) 0%, $colorNeutral000 100%);
  }

  &:nth-child(odd){
    background: $colorNeutral020;

    .ListGradient {
      background: linear-gradient(to bottom, rgba(239, 245, 250, 0) 0%, $colorNeutral020 100%);
    }
  }
}

.ListItem {
  padding: 16px 24px;
  vertical-align: top;

  .StateBadge {
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    padding: 0 8px;
    font-weight: 600;

    &.gray, &.default {
      background: #dfe1e5;
      color: $colorNeutral800;
    }

    &.orange {
      background: #ff8801;
      color: $colorNeutral000;
    }

    &.aqua {
      background: #00cfdd;
      color: $colorNeutral000;
    }

    &.dark {
      background: #6e728f;
      color: $colorNeutral000;
    }

    &.yellow {
      background: #feed73;
      color: $colorNeutral800;
    }

    &.blue {
      background: #5a9df5;
      color: $colorNeutral000;
    }

    &.purple {
      background: #5927d3;
      color: $colorNeutral000;
    }

    &.green {
      background: #16bd62;
      color: $colorNeutral000;
    }

    &.red {
      background: #ff5c5c;
      color: $colorNeutral000;
    }
  }
}

.NotSet {
  color: $colorNeutral090;
}

.Link {
  font-weight: 600;
  color: $colorBlue500;
  display: inline-block;
  vertical-align: top;
  max-height: 91px;
  overflow: hidden;
}

.BadgeEye {
  display: inline-block;
  vertical-align: -3px;
  margin-right: 8px;
  @include svgIcon(16px,16px,$colorNeutral090);
}