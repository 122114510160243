@import "/basics-scss/mixins";

.content {
    position: relative;
    overflow: hidden;
    max-height: 48px;
    max-width: 302px;

    &.subject {

    }
}

.container {

}

.gradient {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 13px;
    z-index: $zIndexLow;
}

.tooltip {
    max-width: 300px;
    word-wrap: break-word;
    z-index: $zIndexPopover;
}
