@import "../../basics-scss/mixins";

.dragAndDropOverlay {
    position: fixed;
    z-index: $zIndexModal;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    background: $colorOverlay;
    padding: 20px 16px;
    -webkit-overflow-scrolling: touch;
    display: none;

    &.show {
        display: flex;
        @include animateFadeIn($transDurationPopup);
    }

    &.tall_content {
        align-items: baseline;
        overflow-y: auto;
    }
}

.dragAndDropContainer {
    position: relative;
    width: 832px;
    max-width: 100%;
    box-shadow: $boxShadowModal;
    border-radius: 4px;
    background-color: $colorNeutral000;
    @include animateMoveIn($transDurationPopup);

    &.hidden {
        display: none;
    }

    @include forMedia(sm){
        width: 100%;
    }

}

.header {
    padding: 24px;
    position: relative;
    display: flex;
    align-items: center;
    @include forMedia(sm){
        padding: 16px;
    }
}

.headerTitle {
    font-size: 22px;
    line-height: 32px;
    color: $colorNeutral800;
    font-family: $fontFamilyHeadline;
    font-weight: 600;
    height: 32px;
}

.topActionButtons {
    margin: 0 0 -8px;
    padding: 0 24px 16px;
    position: relative;

    .button {
        margin: 0 8px 8px 0;
    }

    @include forMedia(sm){
        padding: 0 16px 16px;
    }
}

.addButton {
    position: absolute;
    right: 24px;
    top: 0;

    @include forMedia(sm){
        display: none;
    }
}

.kebabButton {
    visibility: hidden;
    position: absolute;

    @include forMedia(sm){
        visibility: visible;
        position: static;
    }
}

.kebabMenu {
    width: 214px;
    background: $colorNeutral000;
    border-radius: 4px;
    box-shadow: $boxShadowCard;
    padding: 8px 0;
}

.kebabMenuItem {
    padding: 6px 16px;
    color: $colorNeutral800;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    &[disabled]{
        color: $colorNeutral090;
    }
}

.smHidden {
    @include forMedia(sm){
        display: none;
    }
}

.closeOverlay {
    position: fixed;
    top: 50px;
    right: 40px;
    cursor: pointer;
    @include svgIcon(24px,24px,$colorNeutral080);

    @include forMedia(sm){
        display: none;
    }
}

.attachCount {
    font-size: $fontSizeLabel;
    line-height: 1;
    width: 24px;
    height: 24px;
    background: $colorBlue300;
    border-radius: 50%;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colorNeutral000;
}

.dndText {
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    text-align: center;
    font-weight: 600;
    color: $colorNeutral800;
    margin: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dndButton {

}

.close {
    margin-left: auto;
}

.fileIcon {
    @include svgIcon(20px,20px,$colorNeutral800);
}

.footer {
    height: 24px;
    @include forMedia(sm){
        height: 16px;
    }
}

.content {
    padding: 0 24px;

    @include forMedia(sm){
        padding: 0 16px;
    }
}

.contentContainer {

}

.dropZone {
    min-height: 304px;
    border-radius: 4px;
    text-align: center;
    position: relative;

    &.dropHover {
        .dropHoverContent {
            visibility: visible;
        }
    }

    &.hasAttachments {
        min-height: 256px;
        border-left: none;
        border-right: none;
        border-color: transparent;
        margin-bottom: 0;
        @include forMedia(sm){
            min-height: auto;
            margin-bottom: -8px;
        }
    }

    @include forMedia(sm){
        min-height: 236px;
    }
}

.dropHoverContent {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $zIndexHigh;
    border-radius: 4px;
    background: $colorNeutral000;
    visibility: hidden;
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    color: $colorBlue500;
    border: 2px dashed $colorBlue300;
}

.dropHoverContentIcon {
    @include svgIcon(20px,20px,$colorBlue500);
    margin-right: 8px;
}

.dropZoneAttachments {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    justify-content: space-between;

    @include forMedia(sm){
        display: flex;
        flex-direction: column-reverse;
        grid-row-gap: 0;
    }
}

.dropZoneCaption {
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
    color: $colorNeutral090;
    display: flex;
    justify-content: space-between;
    div:last-child {
        text-align: right;
    }
}

.dropZoneTitle {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed $colorNeutral080;
    border-radius: 4px;
    &.static {
        position: static;
        padding: 16px;
        margin-bottom: 16px;
    }
}

.dropZoneTitleContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.uploadedFileContainer {
    padding: 8px;
    border: 1px solid $colorNeutral080;
    border-radius: 4px;
    text-align: left;
    height: 84px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    background: $colorNeutral010;
    @include transition();

    @include forMedia(sm){
        margin-bottom: 8px;
    }

    &.center {
        justify-content: center;
    }

    .plusIcon {
        margin-right: 8px;
        height: 24px;
        @include svgIconColor($colorNeutral090);
    }

    .plusText {
        color: $colorNeutral090;
        font-weight: 600;
    }

    .progressIndicator {
        padding: 20px 32px 0;

        .progress {
            position: relative;
            width: 100%;

            &:before {
                display: block;
                content: '';
                position: absolute;
                background: $colorNeutral080;
                border-radius: 8px;
                left: 0;
                right: 0;
            }

            div {
                position: absolute;
                background: $colorBlue300;
                border-radius: 8px;
                height: 4px;
            }
        }

        .progressText {
            text-align: center;
            color: $colorNeutral800;
            font-size: $fontSizeBody;
            line-height: $lineHeightBody;
        }
    }

    .fileContent {
        display: flex;
        position: relative;
        padding: 8px 8px 0;

        & > div {
            display: flex;

            &:first-child {
                margin-right: 8px;
            }
        }

        .controls {
            border-radius: 4px;
            border: 1px solid $colorNeutral080;
            background: $colorNeutral000;
            box-shadow: $boxShadowCardTaskboard;
            padding: 4px;
            position: absolute;
            top: 0;
            right: 0;
            display: none;

            &.active {
                display: flex;
                @include animateFadeIn();
            }

            & > span {
                margin-right: 0;

                &:last-child {
                    margin-right: 0;
                }
            }

            .fileMenuWrap {
                position: relative;
            }

            .controlsCheckbox {
                padding: 8px;
            }

            @include forMedia(sm){
                display: flex;
            }
        }

        .fileParams {
            display: flex;
            flex-direction: column;
            flex: auto;
        }
    }

    &:hover, &.active {
        background: $colorNeutral060;

        .controls {
            display: flex;
            @include animateFadeIn();
        }
    }

    &.selected {
        border-color: $colorBlue300;
        &:before {
            content: '';
            position: absolute;
            display: block;
            border-radius: 4px;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            border: 1px solid $colorBlue300;
        }
    }

    &.loading {
        overflow: hidden;
        position: relative;
        background: $colorNeutral010!important;
        cursor: default!important;
        &:after {
            @include animatedShadow();
        }
    }
}

.fileContentIcon {
    @include svgIcon(32px,32px);

    &.green {
        @include svgIconColor($colorGreen300);
    }
    &.blue {
        @include svgIconColor($colorBlue300);
    }
}

.fileMenu {
    position: absolute;
    background: $colorNeutral000;
    box-shadow: $boxShadowModal;
    border-radius: 4px;
    z-index: 1;
    min-width: 144px;
    padding: 8px 0;
    bottom: 36px;
    right: 0;

    &.bottom {
        bottom: initial;
        top: 36px;
    }
}

.fileMenuItem {
    padding: 0 8px 0 16px;
    display: flex;
    width: 100%;
    align-items: center;
    height: 32px;

    &:hover {
        background-color: $colorNeutral070;
    }

    &:active {
        background-color: $colorBlue010;
    }
}

.fileMenuIcon {
    margin-right: 8px;
    @include svgIcon(16px,16px,$colorNeutral800);
}

.browseBtn {
    display: inline-block;
    border: 1px #656060 solid;
    cursor: pointer;
    padding: 5px 15px;
}

.fileName {
    position: relative;
    font-size: $fontSizeLabel;
    line-height: $lineHeightLabel;
    font-weight: 600;
    color: $colorNeutral800;
    height: 21px;

    span {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    a {
        color: inherit;
    }
}

.fileSize {
    position: relative;
    font-size: $fontSizeCaption;
    line-height: $lineHeightCaption;
    font-weight: 600;
    color: $colorNeutral600;
    height: 16px;

    span {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.uploadProgress {

}

.errorText {
    color: red;
}

.bodyFreeze {
    overflow: hidden;
}

.checkbox {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    border-radius: 4px;
    border: 2px solid $colorNeutral080;
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    background: $colorNeutral010;
    @include svgIcon(8px, 6px, $colorNeutral000);
    & > * {
        visibility: hidden;
    }
    &:hover {
        background: $colorNeutral060;
    }
    &:active {
        background: $colorNeutral050;
        border-color: $colorNeutral090;
    }
    &.active {
        background: $colorBlue300;
        border-color: $colorBlue500;
        & > * { visibility: visible; }
    }
}

.fileSkeleton {
    display: flex;
    padding: 8px;
}

.fileSkeletonBlank {
    border-radius: 4px;
    background: $colorNeutral077;
}

.fileSkeletonIcon {
    flex: 0 0 32px;
    margin-right: 8px;
    .fileSkeletonBlank {
        height: 32px;
    }
}

.fileSkeletonContent {
    flex: auto;
    .fileSkeletonBlank {
        &:nth-child(1) {
            height: 20px;
            max-width: 79px;
        }
        &:nth-child(2) {
            height: 12px;
            max-width: 138px;
            margin: 4px 0;
        }
        &:nth-child(3) {
            height: 12px;
            max-width: 114px;
        }
    }
}