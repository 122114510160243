@import "../../../basics-scss/mixins";

.sidebarMenu {
    font-family: $fontFamilyMain;
    font-size: $fontSizeBody;
    line-height: $lineHeightBody;
    height: 100%;
    overflow-y: auto;
    @include customScrollbar;

    .category {

    }

    .item {
        position: relative;

        &:hover {
            background: $colorNeutral075;
        }

        &:active {
            background: $colorBlue010;
        }
    }

    & > .item {

    }

    .submenu {
        margin-left: 28px;
    }

    .link {
        color: $colorNeutral800;
        cursor: pointer;
        display: block;
        padding: 10px 28px;
    }

    .expanded {

    }

    .current {
        .link {
            color: $colorBlue500;
        }

        .count {
            color: $colorBlue500;
        }

        .bullet {
            background: $colorBlue500;
        }

        .categoryIcon {
            @include svgIconColor($colorBlue500);
        }
    }

    .bullet {
        position: absolute;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background: $colorNeutral800;
        left: 12px;
        top: 18px;
    }

    .count {
        display: block;
        height: 20px;
        min-width: 24px;
        line-height: 20px;
        text-align: center;
        color: $colorNeutral090;
        position: absolute;
        right: 0;
        top: 10px;
    }

    .categoryIcon {
        position: absolute;
        top: 10px;
        left: 2px;
        @include svgIcon(20px, 20px, $colorNeutral090);
        cursor: pointer;

        &:hover {
            @include svgIconColor($colorNeutral800);
        }
    }

    @include forMedia(sm) {
        padding: 16px;
    }
}
