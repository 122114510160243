@import "../../../basics-scss/mixins";

.CategoryList {

}

.CategoryItem {
  background: $colorNeutral000;
  box-shadow: $boxShadowCardTaskboard;
  border-radius: 8px;
  margin: 0 0 16px;
  padding: 24px;
  cursor: pointer;

  &:hover {
    box-shadow: $boxShadowCardHover;
  }

  @include forMedia(xs){
    padding: 16px;
    border-radius: 0;
  }
}

.Header {
  display: flex;
  justify-content: flex-start;
}

.HeaderSubject {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: $colorNeutral900;

  @include forMedia(sm){
    font-size: 20px;
    line-height: 24px;
    word-break: break-word;
  }
}

.HeaderIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  flex: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 3px;

  img {
    max-width: 24px;
    max-height: 24px;
    width: auto;
    height: auto;
  }
}

.Text {
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  color: $colorNeutral800;

  > div {
    max-height: 100px;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
  }
}

.LinkBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.Link {
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #0F8AF9;
  margin-right: 6px;
}

.LinkIcon {
  padding-top: 4px;
  @include svgIcon(10px, 10px, #0F8AF9);
}

.Footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include forMedia(sm){
    flex-direction: column;
    align-items: flex-start;
  }
}

.DateBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 24px;

  @include forMedia(sm){
    margin: 0 0 0 0;
  }
}

.DateIcon {
  margin-right: 8px;

  @include svgIcon(24px, 24px, #B6BBC9);
}

.DateText {
  font-size: $fontSizeBody;
  line-height: 24px;
  color: $colorNeutral090;
}

.User {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include forMedia(sm){
    margin: 16px 0 0 0;
  }
}

.UserIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
  background: no-repeat center;
  background-size: cover;

  @include svgIcon(24px, 24px, #D5D8DD);
}

.UserText {
  font-size: $fontSizeBody;
  line-height: 24px;
  color: $colorNeutral090;
  word-break: break-word;
}

.Total {
  font-weight: 600;
  font-size: $fontSizeBody;
  line-height: $lineHeightBody;
  margin: 0 0 16px;
  font-family: $fontFamilyMain;
  color: $colorNeutral900;

  @include forMedia(sm){
    display: none;
  }
}
