@import "../../../../../basics-scss/mixins";

.Search {
  position: relative;
}

.Group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.Button {
  width: 100%;
  height: 32px;
  padding: 0 31px 0 8px;
  font-size: $fontSizeInput;
  color: $colorNeutral800;
  background: $colorNeutral010 url("/assets/img/select-arrow.svg?inline") no-repeat right 8px center;
  border: 1px solid $colorNeutral080;
  border-radius: 4px;
  text-align: left;
  cursor: default;

  &:hover {
    background-color: $colorNeutral060;
  }

  &:focus {
    background-color: $colorNeutral000;
    border-color: $colorBlue150;
    box-shadow: inset 0 0 1px 1px $colorBlue150;
  }
}

.Value {
  flex: auto;
  position: relative;
  height: 30px;
  line-height: 30px;
  text-align: left;
}

.ValueText {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Placeholder {
  color: $colorNeutral090;
}

.DateBlock {
  box-shadow: $boxShadowCard;
  border-radius: 4px;
  background: $colorNeutral000;
  display: block;

  @include forMedia(sm){
    border-radius: 0;
    z-index: $zIndexModal;
    position: fixed;
    box-shadow: none;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.DateBlockClose {
    display: none;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 16px 0;
    @include forMedia(sm){
        display: flex;
    }
}

.DatePickers {

}

.DatePickersItem {
    & > div {
        box-shadow: none!important;
    }

    @include forMedia(sm){
        width: auto;
        margin: 0 auto;
        & > div {
          width: auto;
        }
        &:first-child {
            margin-bottom: 16px;
        }
    }
}

.HeaderDate {
  padding: 16px 0 8px;
  margin: 0 20px;
  font-weight: 600;
  font-size: $fontSizeLabel;
  line-height: 32px;
  min-height: 56px;
  color: $colorNeutral900;
  border-bottom: 1px solid #DFE1E5;
  position: relative;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;

  @include forMedia(sm){
    padding: 24px 24px 8px 32px;
    margin: 0 16px;
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    border-bottom: none;
  }
}

.HeaderDateArrow {
  @include svgIcon(20px,20px,$colorNeutral800);
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  left: 0;
  cursor: pointer;

  @include forMedia(sm){
    @include svgIcon(24px,24px,$colorNeutral800);
    height: 24px;
    width: 24px;
    top: 24px;
  }
}

.HeaderDateClose {
  @include svgIcon(24px,24px,$colorNeutral800);
  position: absolute;
  right: 0;
  top: 24px;
  display: none;

  @include forMedia(sm){
    display: block;
  }
}

.ApplyButton {
  text-align: right;
}

.FooterButton {
  @include forMedia(sm){
    display: block;
    width: 100%;
  }
}

.Freeze {
  overflow: hidden;
}

.DateForm {
  padding: 16px;
  width: 312px;
  @include forMedia(sm){
    width: 100%;
    margin: 0 auto;
    padding: 24px 16px;
  }
}

.DateFormHeading {
  font: $fontFamilyHeadline;
  font-weight: 600;
  font-size: $fontSizeH3;
  line-height: $lineHeightH3;
  height: 24px;
  color: $colorNeutral900;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 24px;
  display: none;
  margin-bottom: 24px;

  @include forMedia(sm){
    display: block;
  }
}

.DateFormClose {
  @include svgIcon(24px,24px,$colorNeutral800);
  position: absolute;
  right: 0;
  top: 0;
}

.DateFormField {
  margin-bottom: 14px;
}

.DateFormTitle {
  font-weight: 600;
  font-size: $fontSizeLabel;
  line-height: $lineHeightLabel;
  color: $colorNeutral900;
  margin-bottom: 8px;
}

.DateFormFlex {
  display: flex;
}

.DateFormInput {
  flex: auto;
  position: relative;
}

.DateFormCross {
  @include svgIcon(16px,16px,$colorNeutral090);
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  &:hover {
    @include svgIconColor($colorNeutral800);
  }
}

.DateFormButton {
  flex: 0 0 32px;
  margin-left: 4px;
}

.IntervalBtn {
  overflow: hidden;
  cursor: pointer;
  &:hover {
    .IntervalBtnText {
      color: #0076CC;
    }
    .IntervalBtnIcon {
      @include svgIconColor(#0076CC);
    }
  }
  &:active {
    .IntervalBtnText {
      color: #0068B2;
    }
    .IntervalBtnIcon {
      @include svgIconColor(#0068B2);
    }
  }
}

.IntervalBtnText {
  font-size: $fontSizeButton;
  line-height: 20px;
  margin-left: 26px;
  color: $colorBlue500;
}

.IntervalBtnIcon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  @include svgIcon(20px,20px,$colorBlue500);
}

.Hidden {
  position: absolute;
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  left: -9999px;
  top: -9999px;
}